body {
  font-family: "Montserrat", sans-serif !important;
}

.input-with-icon-div .input-icon {
  top: 19px;
  left: 15px;
}

.email-with-icon .MuiInputBase-input {
  padding-left: 50px !important;
}

.css-16a90qk-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 !important;
}

.textArea {
  overflow-y: scroll !important;
}

.textArea ::-webkit-scrollbar {
  display: block !important;
}

.textarea {
  overflow-y: scroll !important;

}

.scrollableinfo::-webkit-scrollbar {
  width: 5px !important;
  height: 6px !important;
  background-color: rgb(23, 186, 174) !important;
  border: 1px solid #aaa !important;
  display: block !important;
  border-radius: 20px;
}

::-webkit-scrollbar {
  display: none;
}

.scrollableinfo::-webkit-scrollbar-thumb {
  background: rgb(23, 186, 174);
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 10px;
}

.scrollableinfo::-webkit-scrollbar-track {
  background: #aaa;
  border-radius: 10px;
}


.MuiListItemText-root .MuiTypography-root.MuiTypography-body1 {
  font-family: Montserrat, sans-serif !important;
}

.carousel .thumbs-wrapper.axis-vertical {
  display: none;
}

/* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
} */

/* ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 1px solid slategrey;
} */

.css-1ex1afd-MuiTableCell-root {
  border: none !important;
}

.recharts-cartesian-grid-horizontal line {
  stroke-dasharray: 0;
  height: 5px;
}

.recharts-cartesian-grid-vertical line {
  display: none;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  background: rgba(167, 167, 167, 0.2);
  border-radius: 12px;
  border: none !important;
  color: black;
}

.custom-tooltip {
  background-color: #111111;
  padding: 5px 5px;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  border-radius: 6px;
  height: auto;
  -moz-box-shadow: 0 0 8px #c7c7c7;
  -webkit-box-shadow: 0 0 8px #c7c7c7;
  box-shadow: 0 0 8px #c7c7c7;
  width: 80px;
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  top: 98%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.recharts-cartesian-grid-horizontal line:first-child {
  display: none;
}

.css-8c912s-MuiFormLabel-root-MuiInputLabel-root {
  color: transparent !important;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected {
  /* background-color: #1976d2; */
/* color: black !important;
  background-color: #fff !important;
  font-weight: bold !important;
} */

li.Mui-selected {
  color: black !important;
  background-color: white !important;
  font-weight: bold !important
}

.MuiMultiSectionDigitalClockSection-item {
  min-height: auto;
  border-bottom: 1px solid #979797 !important;
  border-right: 0 !important;
}

.css-1s3wx5b-MuiMultiSectionDigitalClock-root {
  border-bottom: 0 !important;
}

.css-14prqje-MuiList-root-MuiMultiSectionDigitalClockSection-root:not( :first-of-type) {
  border-left: 0 !important;
}

.css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item {
  border-bottom: 1px solid #979797;
}

.flag-ui-box .react-tel-input .selected-flag {
  border-radius: 18px 0 0 18px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__label {
  /* border: none;
  background: none; */
  margin: 15px auto 15px;
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: #03293a;
}

.react-calendar__navigation {
  display: flex;
  cursor: pointer;
  margin: 0;
  padding-bottom: 24px;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation button:disabled {
  background: transparent;
  /* color: #000000 !important; */
}

.react-calendar__navigation__arrow>img {
  cursor: pointer;
}

.react-calendar__navigation button {
  flex-grow: 0 !important;
}

.react-calendar button {
  padding: 0;
}

.react-calendar__navigation__arrow {
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

.react-calendar__navigation__label__labelText {
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  color: #1A1A1A;
  font-family: Montserrat, sans-serif;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: rgba(23, 186, 174, 1);
  margin-bottom: 10px;
  background: linear-gradient(180deg, #17BAAE 0%, #29EAA5 100%);
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.react-calendar__tile:disabled,
.react-calendar__month-view__days__day:disabled,
.react-calendar__month-view__days__day--weekend:disabled {
  opacity: 0.2;
  cursor: default;
}


.react-calendar__tile,
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--neighboringMonth {
  border: none;
  background: none;
  font-family: "Montserrat", sans-serif !important;
  text-align: center;
  color: #1A1A1A;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  overflow: visible !important;
  height: 48.7px;
  margin: 0 0 !important;
  max-width: 70.7px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.react-calendar {
  width: 92%;
  margin: auto;
}

.react-calendar__tile--active {
  /* background: linear-gradient(180deg, #17baae 0%, #29eaa5 100%);
  color: black; */
  max-width: 48.7px;
  height: 48.7px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 50% !important;
  background: linear-gradient(180deg, #17baae 0%, #29eaa5 100%) !important;
  color: white !important;
}

.react-calendar__tile--active:hover {
  background: #0094d9;
}

.monthly-dates {
  background: transparent;
  border: 2px solid #0094d9;
}

.react-calendar__month-view__days {
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
  visibility: hidden;
}

.react-calendar__navigation__prev-button:disabled,
.react-calendar__navigation__next-button:disabled {
  color: #e7e8ea !important;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none;
}

.nobordercalendar .react-calendar {
  border: none !important;
}

.active__day {
  background-color: red !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  width: 100px;
  display: table;
  max-width: 130px;
  min-width: 115px;
  padding: 10px 8px;
  height: 60px;
  margin: 5px 0 !important;
  font-size: 18px;
}

::-webkit-input-placeholder {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
}

.carouselsliders .control-arrow {
  display: none
}

.MuiCheckbox-colorPrimary {
  padding: 0 !important;
}

.imagescarousel .carousel-slider {
  height: 100% !important;
}

.imagescarousel .carousel.carousel-slider .control-arrow {
  margin: 20px !important;
}

.imagescarousel .carousel.carousel-slider .control-arrow:hover {
  background-color: transparent !important;
}

.css-qzsgdp-MuiSlider-root {
  background: linear-gradient(to bottom, rgba(23, 186, 174, 1), rgba(41, 234, 165, 1)) !important;
  color: rgba(23, 186, 174, 1) !important;
  height: 30px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  pointer-events: none;
}

textarea::placeholder {
  color: rgba(167, 167, 167, 1);
  /* Change the placeholder color */
  font-family: "Montserrat", sans-serif;
  padding-left: 2px;
  font-size: 16px;
}

.dropdownselection input {
  cursor: pointer
}

.carousel .thumb {
  display: none !important;
}

.editicon {
  cursor: pointer
}

.gm-style>div {
  border: none !important
}

.calendar-container .react-calendar {
  border: none !important;
}

.bookservicecalendar .react-calendar {
  border: 1px solid rgba(104, 127, 137, 0.4) !important;
  padding: 5px;
  border-radius: 20px !important;

  .react-calendar__navigation {
    padding-top: 15px;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: rgba(104, 127, 137, 1) !important;
    font-weight: 600;
  }

  .react-calendar__navigation__label {
    color: black !important;
    font-weight: 600;
  }

  .react-calendar__navigation__arrow {
    font-size: 25px;
    width: 40px;
    height: 40px;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: transparent !important;
  }

}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
}

.pay-now {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 60px;
  padding: 16px;
  background: #003b5b;
  box-shadow: 0 8px 20px rgba(0, 59, 91, 0.16);
  border-radius: 10px;
  cursor: pointer;
}

.pay-now-disabled {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 60px;
  padding: 16px;
  background: #003b5b;
  box-shadow: 0 8px 20px rgba(0, 59, 91, 0.16);
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.2;
}

.card-element-wrapper {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  padding-top: 0;
  height: auto;

  & .MuiBackdrop-root {
    border-radius: 40px;
  }

  .pay-amount {
    width: 90%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 1rem;
    background: rgba(0, 85, 134, 0.1);
    border-radius: 15px;

    #text {
      font-weight: 700;
      font-size: 18px;
      color: #005586;
    }

    #amount {
      font-weight: 700;
      font-size: 24px;
      color: #005586;
    }
  }

  .input-text-field {
    width: 100%;
    height: 55px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(17, 17, 17, 0.6);
    padding: 0 1.25rem;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &:before,
    &:after {
      border: 0;
    }

    & input::-webkit-input-placeholder {
      color: rgba(17, 17, 17, 0.6);
      opacity: 1;
      /* Firefox */
    }
  }

  .input-text-fieldSettings {
    width: 66% !important;
  }

  .input-text-fieldSettings input,
  .ElementsApp,
  .ElementsApp .InputElement {
    font-weight: 600;
  }

  .input-text-fieldSettings input::-webkit-input-placeholder,
  .ElementsApp::-webkit-input-placeholder,
  .ElementsApp .InputElement::-webkit-input-placeholder {
    font-weight: 600;
  }

  .card-number {
    width: 100%;
  }

  .InputContainer input {
    font-weight: 500;
    font-size: 16px !important;
    color: #111111;
  }


  .card-number,
  .card-expiry,
  .card-cvv {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #ffffff;
    border-radius: 20px;
    padding: 16px;
    box-sizing: border-box;
    color: #365462;
    font-size: 16px;
    height: 55px;
  }

  .CardNumberField-input-wrapper .InputContainer .InputElement {
    color: #365462;
    font-size: 16px;
  }

  .CardBrandIcon-container {
    right: 0;
  }

  .label {
    color: black;
    font-weight: 600;
    font-size: 18px;
    margin: 20px 0 12px;
    font-family: Montserrat, sans-serif;
  }
}

.review-input,
.review-input:focus-visible {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.profile-icon {
  width: 27px;
  height: 27px;
}

.paymentbox {
  max-height: 378px !important;

}

.timepickerinput input {
  text-align: center !important;
  padding: 0px !important;
  height: 45px !important;
  background-color: #fff !important;
}

.explorescroll {
  margin-bottom: 0.5rem !important;
}

@media screen and (max-width: 1380px) {
  .explorescroll {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important;
  }
}


@media(min-width: 1700px) {
  .toolbarnav {
    min-height: 100px !important;
  }

  /*.explore-list{*/
  /*  max-height: calc(100vh - 310px) !important;*/
  /*}*/
  .responsivecontainer {
    height: 465px !important;
  }

  .subbox1 {
    height: 35vh !important;
  }

  .paymentbox {
    max-height: 378px !important;

  }
}

.dropdownselection .MuiInputBase-input {
  height: 25px !important;
  border-radius: 8px !important;
}

@media screen and (max-width: 768px) {
  .card-element-wrapper {
    top: 50px;
  }
}

@media screen and (max-height: 630px) and (min-height: 550px) {
  .paymentbox {
    height: 49vh !important;

  }
}

.messageinput.chat-message {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
}

.messageinput.chat-message::-webkit-input-placeholder {
  color: #00000090;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .card-element-wrapper {
    top: 75px;
  }
}

.card-element-wrapper .input-text-field:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: 0;
}

@media screen and (max-width: 500px) {
  .review-input {
    width: 95% !important;
  }

  .react-calendar__navigation__label {
    font-size: 15px !important;
  }

  .react-calendar__tile--active {
    height: 35px !important;
  }

  .exploredatefiltebox {
    overflow: auto !important;
  }

  .exploretime {
    width: 85px;
  }

  .serviceaddinfo {
    line-height: 40px;
  }

  .noservicenone {
    display: none !important;
  }

  .spanseperator {
    display: none !important;
    padding: 10px 0 !important
  }

  .completedlineh {
    line-height: 35px;
  }

  .paymentmodalxs {
    height: 550px !important;
  }

  .cardimage {
    height: 25px !important;
    width: 25px !important;
  }

  .crossicontiming {
    right: 2.5rem !important;
  }

  .bookrightcrossicon {
    left: 15px !important;
    top: 20px !important;
  }

  .bookcrossicon {
    right: 15px !important;
    top: 20px !important;
  }

  .successfully-logo-icon {
    height: 100px;
  }
}

.customtimepickertop {
  top: -209px !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}


@media screen and (max-width: 768px) {
  .messageinput {
    padding-right: 2rem
  }

}

.dotted-list li::marker {
  font-weight: 900;
  color: #000000;
}

.flag-ui-box .react-tel-input .flag-dropdown {
  background: transparent;
  border-radius: 15px 0 0 15px;
  border-color: #D9D9D9;
}

.flag-ui-box .react-tel-input input {
  color: #000 !important;
  font-weight: 600 !important;
  height: 60px !important;
  width: 100% !important;
}

.flag-ui-box .react-tel-input input::-webkit-input-placeholder {
  font-weight: 500 !important;
}

.image-modal-slider-img {
  height: calc(100vh - 65px);
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    margin: 10px !important;
    padding: 15px;
  }

  .image-modal-slider-img {
    height: calc(60vh - 65px);
  }

  .dropdownselection .MuiInputBase-input {
    height: 42px !important;
    border-radius: 18px !important;
  }

  .messageinput.chat-message {
    font-size: 12px;
    padding-right: 50px !important;
  }

  .react-calendar {
    width: 100%;
  }

  .edit-plus-img {
    height: 25px;
    position: relative;
    top: -3px;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    max-width: 120px;
    min-width: 105px;
    padding: 5px 8px;
    height: 50px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .toolbarnav {
    min-height: 90px !important;
  }

  .image-modal-slider-img {
    height: calc(80vh - 65px);
  }

  .edit-plus-img {
    height: 30px;
    position: relative;
    top: -3px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .profile-icon {
    width: 27px;
    height: 27px;
  }

  .image-modal-slider-img {
    height: calc(100vh - 65px);
  }

  .toolbarnav {
    min-height: 90px !important;
  }

  .image-size-adjust {
    height: 40px;
  }

  .responsive-btn-class {
    height: 50px !important;
    font-size: 14px !important;
    margin-top: 20px !important;
  }

  .flag-ui-box .react-tel-input input {
    height: 60px !important;
  }

  .edit-plus-img {
    height: 30px;
    width: 30px;
  }

  .successfully-logo-icon {
    height: 100px;
    width: 100px;
  }

  .height-50 {
    height: 50px !important;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .toolbarnav {
    min-height: 90px !important;
  }

  .image-modal-slider-img {
    height: calc(100vh - 65px);
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1919px) {
  .toolbarnav {
    min-height: 90px !important;
  }

  .image-modal-slider-img {
    height: calc(100vh - 65px);
  }

  .fixed-btn-div {
    right: 30px !important;
    bottom: 20px !important;
  }
}

@media only screen and (min-width: 2501px) and (max-width: 4000px) {
  .fixed-btn-div {
    right: 19.2% !important;
    bottom: 30px !important;
    width: 12% !important;
  }
}

.scroll-show-div::-webkit-scrollbar {
  width: 6px;
  border-radius: 25px;
  display: block;
}

.scroll-show-div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
}

.scroll-show-div::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  outline: 1px solid #a0a0a0;
  border-radius: 25px;
}